import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will show you how to upgrade from the now deprecated `}<inlineCode parentName="p">{`<details-dialog>`}</inlineCode>{` element to the latest `}<a parentName="p" {...{
        "href": "/components/dialog/rails/alpha/"
      }}><inlineCode parentName="a">{`Primer::Alpha::Dialog`}</inlineCode></a>{`
component.`}</p>
    <h2>{`Migrating from `}<inlineCode parentName="h2">{`<details-dialog>`}</inlineCode>{` to `}<inlineCode parentName="h2">{`Primer::Alpha::Dialog`}</inlineCode></h2>
    <p><inlineCode parentName="p">{`details-dialog`}</inlineCode>{` uses the `}<inlineCode parentName="p">{`details`}</inlineCode>{` and `}<inlineCode parentName="p">{`summary`}</inlineCode>{` elements to render a dialog. This is different than the `}<a parentName="p" {...{
        "href": "https://primer.style/design/components/dialog/rails/alpha"
      }}>{`Primer `}<inlineCode parentName="a">{`Dialog`}</inlineCode>{` component`}</a>{` that uses a container with `}<inlineCode parentName="p">{`role="dialog"`}</inlineCode>{` attached.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<details-dialog>`}</inlineCode>{` differs from the `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` in 3 different ways:`}</p>
    <ol>
      <li parentName="ol">{`The trigger uses the `}<inlineCode parentName="li">{`summary`}</inlineCode>{` element. `}</li>
      <li parentName="ol">{`The dialog's body (`}<inlineCode parentName="li">{`details-dialog`}</inlineCode>{`) lives within the `}<inlineCode parentName="li">{`details`}</inlineCode>{` element.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`<details>`}</inlineCode>{` wrapper contains both the trigger button (`}<inlineCode parentName="li">{`summary`}</inlineCode>{`) and the dialog itself (`}<inlineCode parentName="li">{`details-dialog`}</inlineCode>{`). `}</li>
    </ol>
    <p>{`For example, here's how `}<inlineCode parentName="p">{`details-dialog`}</inlineCode>{` might be used:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<details>
  <summary>Open dialog</summary>
  <details-dialog>
    Modal content
    <button type="button" data-close-dialog>Close</button>
  </details-dialog>
</details>
`}</code></pre>
    <p>{`Here's an example on how `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` might be used:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`render(Primer::Alpha::Dialog.new(title: 'Dialog Title', subtitle: 'Optional subtitle', size: :large)) do |d|
  d.with_show_button { "Open dialog" }
  d.with_body { "Modal content" }
end
`}</code></pre>
    <p>{`Both `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` and `}<inlineCode parentName="p">{`<details-dialog>`}</inlineCode>{` will render similar dialogs, albeit with a few key differences:`}</p>
    <ul>
      <li parentName="ul">{`Styling`}</li>
      <li parentName="ul">{`Functionality and semantics`}</li>
    </ul>
    <h3>{`Styling differences`}</h3>
    <p><inlineCode parentName="p">{`<details-dialog>`}</inlineCode>{` and `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` will have some minor style differences, such as with padding and margins that are applied by default. Please utilize these updated styles and refrain from overriding styles to make it appear like the previous design.
For custom class usage, we may apply those classes onto `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` through any of the available slots, but prefer usage of `}<a parentName="p" {...{
        "href": "https://primer.style/view-components/lookbook/pages/system_arguments/"
      }}><inlineCode parentName="a">{`system_arguments`}</inlineCode></a>{` instead, if applicable.`}</p>
    <h3>{`Functionality and semantic differences`}</h3>
    <p><inlineCode parentName="p">{`details-dialog`}</inlineCode>{` is rendered as a `}<inlineCode parentName="p">{`details/summary`}</inlineCode>{` pair. This means that `}<inlineCode parentName="p">{`<details-dialog>`}</inlineCode>{` relies on the native semantics of the `}<inlineCode parentName="p">{`details/summary`}</inlineCode>{` pairing, with the `}<inlineCode parentName="p">{`summary`}</inlineCode>{` element acting as the trigger, and the `}<inlineCode parentName="p">{`details`}</inlineCode>{` element acting as the container for both the trigger and the dialog.`}</p>
    <p><inlineCode parentName="p">{`details-dialog`}</inlineCode>{` does not require an accessible name to be added to the dialog, whereas `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` will require a name to be added via the `}<inlineCode parentName="p">{`title`}</inlineCode>{` argument which is referenced as the accessible name of the dialog.
The `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` component also provides a close button by default, unlike in `}<inlineCode parentName="p">{`details-dialog`}</inlineCode>{` where the developer must add one themselves.`}</p>
    <h3>{`Converting a dialog`}</h3>
    <p>{`Here's how a `}<inlineCode parentName="p">{`details-dialog`}</inlineCode>{` might appear:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<details class="float-right" id="confirm-submit">
    <%= render Primer::ButtonComponent.new(tag: :summary, scheme: :danger) do %> Continue with step <% end %>
    <details-dialog class="anim-fade-in fast Box Box--overlay d-flex flex-column">
        <div class="Box-header">
            <button class="Box-btn-octicon btn-octicon float-right" type="button" aria-label="Close Dialog" data-close-dialog>
            <%= primer_octicon(:x) %>
            </button>
            <h3 class="Box-title">Do you want to continue with this action?</h3>
        </div>

        <%= render(Primer::Beta::Flash.new(scheme: :danger, full: true, icon: "alert")) do %>
            Please follow all steps listed below.
        <% end %>
        <div class="Box-body overflow-auto">
            <ul class="mb-3 ml-3">
                <li>Step 1: Do action</li>
                <li>Step 2: Do second action</li>
                <li>Step 3: Do third action</li>
            </ul>
            <%= render Primer::ButtonComponent.new(block: true, scheme: :danger, "data-close-dialog": "", "data-toggle-for": "continue-action") do %>
                I understand, continue
            <% end %>
        </div>
    </details-dialog>
</details>
`}</code></pre>
    <p>{`If we wanted to convert the `}<inlineCode parentName="p">{`details-dialog`}</inlineCode>{` above to use `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{`, we can do the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`<%= render(Primer::Alpha::Dialog.new(title: "Do you want to continue with this action?")) do |d| %>
    <% d.with_show_button(color: :danger) { 'Continue with action' } %>
    <% d.with_body(p: 0) do %>
        <%= render(Primer::Beta::Flash.new(scheme: :danger, full: true, icon: "alert", mt: 1)) do %>
            Please follow all steps listed below.
        <% end %>
        <div class="Box-body overflow-auto">
            <ul class="mb-3 ml-3">
                <li>Step 1: Do action</li>
                <li>Step 2: Do second action</li>
                <li>Step 3: Do third action</li>
            </ul>
            <%= render Primer::ButtonComponent.new(block: true, scheme: :danger, "data-close-dialog": "", "data-toggle-for": "continue-action") do %>
                I understand, continue
            <% end %>
        </div>
    <% end %>
<% end %>
`}</code></pre>
    <p>{`In the example above, both the title and close button will be rendered by the Primer component.
For the button that triggers the dialog, the `}<inlineCode parentName="p">{`show_button`}</inlineCode>{` slot can be used and supplied with text to render the button.
As mentioned earlier, Primer allows usage of `}<a parentName="p" {...{
        "href": "https://primer.style/view-components/lookbook/pages/system_arguments/"
      }}><inlineCode parentName="a">{`system_arguments`}</inlineCode></a>{` to further customize styles on a component. `}</p>
    <h4>{`How can I handle `}<inlineCode parentName="h4">{`<include-fragment>`}</inlineCode>{` in `}<inlineCode parentName="h4">{`Primer::Alpha::Dialog`}</inlineCode>{`?`}</h4>
    <p>{`We can add the `}<inlineCode parentName="p">{`<include-fragment>`}</inlineCode>{` inside of the dialog body:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`<% d.with_body do %>
    <include-fragment src="/issues">
        <%= render(Primer::Beta::Spinner.new(my: 6, display: :block)) %>
    </include-fragment>
<% end %>
`}</code></pre>
    <p>{`There may be slight differences with how `}<inlineCode parentName="p">{`include-fragment`}</inlineCode>{` loads, so it's important to take extra caution when using.`}</p>
    <p>{`Some `}<inlineCode parentName="p">{`details-dialogs`}</inlineCode>{` will include a `}<inlineCode parentName="p">{`<include-fragment>`}</inlineCode>{` element without `}<inlineCode parentName="p">{`src`}</inlineCode>{` being directly applied onto the element. This is usually due to the `}<inlineCode parentName="p">{`src`}</inlineCode>{` being applied elsewhere, such as `}<a parentName="p" {...{
        "href": "https://github.com/github/details-dialog-element#deferred-loading"
      }}>{`on the `}<inlineCode parentName="a">{`details-dialog`}</inlineCode>{` element itself`}</a>{`.`}</p>
    <p>{`For example the following code example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<details>
  <summary>Robots</summary>
  <details-dialog src="/robots" preload>
    <include-fragment>Loading…</include-fragment>
  </details-dialog>
</details>
`}</code></pre>
    <p>{`Could be migrated to `}<inlineCode parentName="p">{`Primer::Alpha::Dialog`}</inlineCode>{` in the code example below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`<%= render(Primer::Alpha::Dialog.new(title: "Robots", visually_hide_title: true)) do |d| %>
    <% d.with_show_button { 'Robots' } %>
    <% d.with_body do %>
        <include-fragment src="/robots">
            Loading…
        </include-fragment>
    <% end %>
<% end %>
`}</code></pre>
    <p>{`There may be some additional options, such as if deferring loading of the `}<inlineCode parentName="p">{`include-fragment`}</inlineCode>{` by using the `}<inlineCode parentName="p">{`loading="lazy"`}</inlineCode>{`.
Additional options available can be found within the `}<a parentName="p" {...{
        "href": "https://github.com/github/include-fragment-element"
      }}>{`documentation for `}<inlineCode parentName="a">{`include-fragment`}</inlineCode></a>{`.`}</p>
    <p>{`Please see the following for complete descriptions and examples.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/dialog/rails/alpha/"
        }}><inlineCode parentName="a">{`Primer::Alpha::Dialog`}</inlineCode>{` component`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/alpha/dialog/default"
        }}><inlineCode parentName="a">{`Primer::Alpha::Dialog`}</inlineCode>{` Lookbook examples`}</a></li>
    </ul>
    <p>&nbsp;</p>
    <p><a parentName="p" {...{
        "href": "/guides/development/rails#upgrading-deprecated-components"
      }}>{`←`}{` Back to development docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      